import * as constants from '@/constants';

export const utilSetLocalStorage = (storageKey: string, storageValue: string) => {
  localStorage.setItem(storageKey, storageValue);
};

export const utilGetLocalStorage = (storageKey: string) => localStorage.getItem(storageKey);

export const utilCheckUserSubscribed = (): boolean => {
  const userType = utilGetLocalStorage('UserType');
  if (userType && (JSON.parse(userType) === constants.SUBSCRIBER_USER_TYPE || JSON.parse(userType) === constants.SUBSCRIBER_SUPER_USER_TYPE || JSON.parse(userType) === constants.SUBSCRIBER_MEDICAL_DEVICE_USER_TYPE)) {
    return true;
  }
  return false;
};

export const utilCheckUserPromotionalModal = (): boolean => {
  const userType = utilGetLocalStorage('UserType');
  if (userType && (JSON.parse(userType) === constants.SUBSCRIBER_USER_TYPE || JSON.parse(userType) === constants.SUBSCRIBER_SUPER_USER_TYPE || JSON.parse(userType) === constants.DATA_TYPING_USER_TYPE || JSON.parse(userType) === constants.SUBSCRIBER_MEDICAL_DEVICE_USER_TYPE)) {
    return true;
  }
  return false;
};

export const utilCheckMigrationModalShown = (): boolean => {
  const migrationModalShown = utilGetLocalStorage('migrationModalShown');
  if (migrationModalShown && JSON.parse(migrationModalShown) === true) {
    return true;
  }
  return false;
};

export const utilCheckSuperUserSubscribed = (): boolean => {
  const userType = utilGetLocalStorage('UserType');
  if (userType && (JSON.parse(userType) === constants.SUBSCRIBER_SUPER_USER_TYPE || JSON.parse(userType) === constants.SUBSCRIBER_MEDICAL_DEVICE_USER_TYPE)) {
    return true;
  }

  return false;
};

export const utilCheckMedicalDeviceUserSubscribed = (): boolean => {
  const userType = utilGetLocalStorage('UserType');
  if (userType && (JSON.parse(userType) === constants.SUBSCRIBER_MEDICAL_DEVICE_USER_TYPE)) {
    return true;
  }

  return false;
};

export const utilRemoveItemLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const utilClearLocalStorage = () => {
  localStorage.clear();
};
